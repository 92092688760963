import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { StyledContainer } from "../../components/core"
import HeaderParagraph from "../../components/header-paragraph"
import Layout from "../../components/layout-desktop"
import SEO from "../../components/seo"

const backupPolicyPage = () => {
  const data = useStaticQuery(graphql`
    query backupPolicy {
      markdownRemark(
        fileAbsolutePath: { regex: "/src/content/terms/backup_policy/" }
      ) {
        htmlAst
        frontmatter {
          title
        }
      }
    }
  `)
  const markdownData = data.markdownRemark

  return (
    <Layout>
      <SEO
        title="Backup policy"
        description="Backup policy of the SixEye service."
      />
      <StyledContainer text>
        <HeaderParagraph
          headerAs="h1"
          title={markdownData.frontmatter.title}
          htmlAst={markdownData.htmlAst}
        />
      </StyledContainer>
    </Layout>
  )
}
export default backupPolicyPage;
